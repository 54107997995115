@font-face {
  font-family: 'RobotoMedium';
  src:
    local('RobotoMedium'),
    url('../fonts/Roboto/Roboto-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'RobotoRegular';
  src:
    local('RobotoRegular'),
    url('../fonts/Roboto/Roboto-Regular.ttf') format('truetype');
}
