body > iframe[name='wealthica-connect-widget'] {
  pointer-events: none;
}

.ReactModal__Body--open,
.ReactModal__Html--open {
  overflow: hidden;
}

.ReactModal__Content,
.ReactModal__Content--after-open {
  border-radius: 0 !important;
}

::-webkit-scrollbar {
  -webkit-border-radius: 5px;
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #f5f5f5;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #b3b1b1;
}
